import { OTP_SEND, HTTP } from '../../utility/api';
import { SEND_OTP_SUCCESS, SEND_OTP_ERROR, SEND_OTP_LOADING, SEND_OTP_RESET } from '../types';

export const fetchingRequest = () => ({
  type: SEND_OTP_LOADING
})

export const fetchingError = (error) => ({
  type: SEND_OTP_ERROR,
  payload: error
})

export const fetchingSuccess = (data) => ({
  type: SEND_OTP_SUCCESS,
  payload: data
})

export const sendOtpReset = () => ({
  type: SEND_OTP_RESET
})

export function sendOtp(phone) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(OTP_SEND, { phone })
      .then((response) => {
        if (response.data.success) {
          dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
      });
  }
};