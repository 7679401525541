import { GET_CITY_LIST_SUCCESS, GET_CITY_LIST_LOADING, GET_CITY_LIST_ERROR, GET_CITY_LIST_RESET } from '../types';
import { HTTP, GET_CITY_LIST } from '../../utility/api';

export const fetchingRequest = () => ({
  type: GET_CITY_LIST_LOADING
});
export const fetchingError = (error) => ({
  type: GET_CITY_LIST_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: GET_CITY_LIST_SUCCESS,
  payload: data
})
export const getCityListReset = () => ({
  type: GET_CITY_LIST_RESET
})

export function getCityList(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.get(GET_CITY_LIST)
      .then((response) => {
        if (response.data.success) {
            dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
    });
  }
};