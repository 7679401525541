import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { login, loginReset } from "../../redux/actions/loginAction";

function LoginScreen({ login, loginReset, result }) {
  const clientID = '18967777559-00bt2p4ij0qhtfca08eibf4hbcaopvmv.apps.googleusercontent.com';


  useEffect(() => {
    loginReset();
  }, []);

  const [emailPhoneError, setEmailPhoneError] = useState(false);
  const [field, setField] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState("")
  const [error, setError] = useState(false);
  const mob_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

  const validateEmail = (email) => {
    if (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
  };

  const loginNow = () => {
    setEmailPhoneError(false);
    setPasswordError(false);
    if ((validateEmail(field) || mob_regex.test(field)) && password) {
      if (validateEmail(field)) {
        login({
          email: field,
          phone: "",
          password: password
        });
      } else if (mob_regex.test(field)) {
        login({
          email: "",
          phone: field,
          password: password
        });
      }
    } else {
      if (!validateEmail(field) && !mob_regex.test(field)) {
        setEmailPhoneError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
    }
  }

  useEffect(() => {
    if (result.login.success) {
      if (result.login.payload.user.type == 1) {
        window.location.href = '/';
        loginReset();
      } else {
        window.location.href = '/';
        loginReset();
      }
    }
    if (result.login.error) {
      setError(true)
      setMessage(result.login.payload.response.data.error)
    }
  }, [result.login])


  const responseGoogle = (res) => {
    console.log(res);
  }

  const logout = () => {
    console.log("loged out");
  }

  const fail = (res) => {
    console.log(res);
  }

  let publicUrl = process.env.PUBLIC_URL + '/'
  return (
    <>
      <section
        class="hero-section ptb-100 background-img full-screen"
        style={{
          background:
            "url('assets/img/hero-bg-1.jpg')no-repeat center center / cover",
        }}
      >
        <div class="container">
          <div class="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div class="col-md-7 col-lg-6">
              <div class="hero-content-left text-white">
                <h1 class="text-white">Welcome Back !</h1>
                <p class="lead">
                  Keep your face always toward the sunshine - and shadows will
                  fall behind you.
                </p>
              </div>
            </div>
            <div class="col-md-5 col-lg-5">
              <div class="card login-signup-card shadow-lg mb-0">
                <div class="card-body px-md-5 py-5">
                  <div class="mb-5">
                    <h5 class="h3">Login</h5>
                    <p class="text-muted mb-0">
                      Sign in to your account to continue.
                    </p>
                  </div>

                  <div class="login-signup-form">
                    <div class="form-group">
                      <label class="pb-1">Email Address</label>
                      <div class="input-group input-group-merge">
                        <div class="input-icon">
                          <span class="ti-email color-primary"></span>
                        </div>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="name@yourdomain.com"
                          onChange={(e) => setField(e.target.value)} style={{ marginBottom: 30 }}
                        />
                      </div>
                      {emailPhoneError &&
                        <span style={{ color: 'red' }}>
                          Enter email or phone
                        </span>
                      }
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <label class="pb-1">Password</label>
                        </div>
                        <div class="col-auto">
                          <a
                            href="password-reset.html"
                            class="form-text small text-muted"
                          >
                            Forgot password?
                          </a>
                        </div>
                      </div>
                      <div class="input-group input-group-merge">
                        <div class="input-icon">
                          <span class="ti-lock color-primary"></span>
                        </div>
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Enter your password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {passwordError &&
                        <span style={{ color: 'red' }}>
                          Password not valid
                        </span>
                      }
                    </div>

                    {error == 1 &&
                      <div className="row">
                        <alert className="alert-danger alert-padding"> {message} </alert>
                      </div>
                    }

                    <button class="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3" onClick={() => loginNow()}>
                      Sign in
                    </button>
                  </div>
                </div>
                <div class="card-footer bg-transparent border-top px-md-5">
                  <small>Not registered?</small>
                  <Link to="/signup-two" class="small">
                    {" "}
                    Create account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-img-absolute">
          <img
            src="assets/img/hero-bg-shape-1.svg"
            alt="wave shape"
            class="img-fluid"
          />
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    result: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    loginReset: () => dispatch(loginReset())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
