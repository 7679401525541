import { SUBSCRIBE_SUCCESS, SUBSCRIBE_ERROR, SUBSCRIBE_LOADING, SUBSCRIBE_RESET } from '../types';
import { HTTP, REGISTER, SUBSCRIBE } from '../../utility/api';

export const fetchingRequest = () => ({
  type: SUBSCRIBE_LOADING
});
export const fetchingError = (error) => ({
  type: SUBSCRIBE_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: SUBSCRIBE_SUCCESS,
  payload: data
})
export const subscribeReset = () => ({
  type: SUBSCRIBE_RESET
})

export function subscribe(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(SUBSCRIBE, { email: data })
      .then((response) => {
        if (response.data.success) {
          dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
      });
  }
};