import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";


export default function PrivacyPolicy() {
  return (
    <>
      <Layout>
        <Navbar />
        <div style={{ marginLeft: '100px', marginRight: '100px', marginTop: '100px' }}>
          <div class="page" title="Page 3">
            <div class="section">
              <div class="layoutArea">
                <div class="column">
                  <h2>Privacy Policy</h2>
                  {/* <p>Last updated: [26th March, 2023]</p> */}
                  <p>At WheelPeGo, we are committed to protecting the privacy of our customers and users.</p>
                  <p>Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you consent to the collection and use of your personal information as described in this Privacy Policy.</p>
                  <h4><u>Information we collect:</u></h4>
                  <ul>
                    <li>
                      <p>● &nbsp;We collect personal information such as your name, address, email address, phone
                        number, and payment information when you use our services.</p>
                    </li>
                    <li>
                      <p>● &nbsp;We also collect non-personal information such as browser type, IP address, and device information.</p>

                    </li>
                  </ul>
                  <h4><u>How we use your information:</u></h4>
                  <ul>
                    <li>
                      <p>● &nbsp;We use your personal information to provide and improve our services, communicate
                        with you about your orders, and provide customer support.</p>
                    </li>
                    <li>
                      <p>● &nbsp;We may also use your information to send you marketing communications and
                        promotions if you have opted-in to receive them.</p>
                    </li>
                    <li>
                      <p>● &nbsp;We do not sell or share your personal information with third parties for their marketing
                        purposes.</p>

                    </li>
                  </ul>
                  <h4><u>Data retention:</u></h4>
                  <ul>
                    <li>
                      <p>● &nbsp;We retain your personal information only for as long as necessary to provide our
                        services to you and as required by law.</p>
                    </li>
                    <li>
                      <p>● &nbsp;We take reasonable steps to ensure that your personal information is accurate,
                        complete, and up-to-date.</p>

                    </li>
                  </ul>
                  <h4><u>Security:</u></h4>
                  <ul>
                    <li>
                      <p>● &nbsp;We implement industry-standard security measures to protect your personal
                        information from unauthorized access, disclosure, and use.</p>
                    </li>
                    <li>
                      <p>● &nbsp;We use encryption to protect sensitive information and restrict access to personal
                        information on a need-to-know basis.</p>

                    </li>
                  </ul>
                  <h4><u>Cookies and tracking:</u></h4>
                  <ul>
                    <li>
                      <p>● &nbsp;We use cookies and other tracking technologies to collect non-personal information
                        about your use of our website. This information is used to improve our website and
                        provide a better user experience.</p>
                    </li>
                    <li>
                      <p>● &nbsp;You may disable cookies in your browser settings, but this may limit your ability to use some features of our website.</p>

                    </li>
                  </ul>
                  <h4><u>Third-party links:</u></h4>
                  <ul>
                    <li>
                      ● Our website may contain links to third-party websites.
                    </li>
                    <li>
                      ● &nbsp;We are not responsible for the privacy practices or content of these websites.
                    </li>
                    <li><p>● &nbsp;We encourage you to review the privacy policies of these websites before providing any personal information.</p></li>
                  </ul>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="page" title="Page 4">
            <div class="section">
              <div class="layoutArea">
                <div class="column">
                  <h4><u>Changes to our Privacy Policy:</u></h4>
                  <p>WheelPeGo reserves the right to update or change this Privacy Policy at any time.</p>
                  <p>We will post the revised Privacy Policy on our website and update the "last updated" date at the top of this page.</p>
                  <h4><u>Contact us:</u></h4>
                  <p> If you have any questions or concerns about our Privacy Policy, please contact us at - contact@athravtechnologies.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer space />
      </Layout>
    </>
  );
}
