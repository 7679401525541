import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Why Peoples Love WheelPeGo?</h2>
                <p className="lead">
                  Revolutionising Your Delivery System, Simplifying Your Deliveries, We have the answer to your logistics problems
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-vector text-white"></span>
                </div>
                <h5>Delivery Management</h5>
                <p>We streamline the entire delivery process, from pickup to final delivery, making it faster and more efficient.</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-lock text-white"></span>
                </div>
                <h5>Real-Time Tracking</h5>
                <p>
                  Track your delivery in real-time and receive regular updates on its progress
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Affordable Pricing</h5>
                <p>
                  Our pricing is competitive and transparent. You can get the most value for your money.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
