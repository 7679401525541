import { LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_RESET } from '../types';
import { HTTP, LOGIN } from '../../utility/api';
import { USER_EMAIL, USER_FIRST_NAME, USER_ID, USER_LAST_NAME, USER_PHONE, USER_TOKEN, USER_TYPE } from '../../utility/constants';

export const fetchingRequest = () => ({
  type: LOGIN_LOADING
});
export const fetchingError = (error) => ({
  type: LOGIN_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data
})
export const loginReset = () => ({
  type: LOGIN_RESET
})

export function login(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(LOGIN, data)
      .then((response) => {
        if (response.data.success) {
            dispatch(fetchingSuccess(response.data));
            localStorage.setItem(USER_TOKEN, response.data.token);
            localStorage.setItem(USER_ID, response.data.user.id);
            localStorage.setItem(USER_EMAIL, response.data.user.email);
            localStorage.setItem(USER_PHONE, response.data.user.phone);
            localStorage.setItem(USER_FIRST_NAME, response.data.user.first_name);
            localStorage.setItem(USER_LAST_NAME, response.data.user.last_name);
            localStorage.setItem(USER_TYPE, response.data.user.type);
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
    });
  }
};