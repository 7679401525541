import { Button } from "@coreui/coreui";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { subscribe, subscribeReset } from "../../redux/actions/subscribeAction";

function Subsribe({ subscribe, subscribeReset, result }) {

  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");

  const submitRequest = () => {
    subscribe(email);
  }

  useEffect(() => {
    subscribeReset();
  }, [])

  useEffect(() => {
    if (result.subscribe.success) {
      setEmail("");
      subscribeReset();
      console.log(result.subscribe.payload.message);
      setResponse(result.subscribe.payload.message)
    }
    if (result.subscribe.error) {
      subscribeReset();
    }
    if (result.subscribe.reset) {
    }
  }, [result.subscribe])

  return (
    <>

      <div className="shape-img subscribe-wrap">

        <img
          src="assets/img/footer-top-shape.png"
          alt="footer shape"
          className="img-fluid"
        />

        <div className="container">

          <div className="row justify-content-center">
            {response &&
              <div className="row" style={{ zIndex: 100000000, maxWidth: 600, justifyContent: 'center' }}>
                <div className="col-sm-12 mt-3">
                  <div className="alert alert-success" role="alert">
                    {response}
                  </div>
                </div>
              </div>
            }
            <div className="col-md-6">
              <div
                action="#"
                method="post"
                className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"
              >

                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control input"
                    id="email-footer"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="info@mail.com"
                  />
                  <input
                    type="submit"
                    className="button btn solid-btn"
                    id="submit-footer"
                    value="Subscribe"
                    onClick={() => submitRequest()}
                  />

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    result: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    subscribeReset: () => dispatch(subscribeReset()),
    subscribe: (data) => dispatch(subscribe(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subsribe)
