import axios from "axios";
import { BASE_URL, USER_TOKEN } from "./constants";

export const HTTP = axios.create(
  {
    baseURL: BASE_URL,
    timeout: 20000,
    headers: { 'Content-Type': 'application/json' },
  }
);

// HTTP.interceptors.request.use(
//   async (config) => {
// const token = await AsyncStorage.getItem(USER_TOKEN);
// console.log("token", token);
// if(token) {
//   config.headers.Authorization = token;
// }
// return config;
//   },
//   (error) => Promise.reject(error),
// );


// USER API URLS
export const REGISTER = 'register';
export const LOGIN = 'login';
export const SUBSCRIBE = 'subscribe'
export const POST_CREATE_PARTNER = 'partner';
export const GET_VEHICLE_TYPES = 'vehicles';
export const GET_CITY_LIST = 'cities';
export const CREATE_BOOKING = 'createBooking';
export const GET_BOOKING = 'getBooking';
export const GET_BOOKINGS = 'bookings';
export const OTP_SEND = 'otp/send';
export const OTP_VERIFY = 'otp/verify';

// Admin APIs
export const BOOKINGS = 'admin/bookings';
export const BOOKING = 'admin/booking';
export const UPDATE_VEHICLE = 'admin/vehicle';
export const UPDATE_CITY = 'admin/update';
export const POST_CITY = 'admin/city';
export const POST_VEHICLE = 'admin/vehicle';
export const GET_PARTNERS = 'admin/partners';
export const ADMIN_PARTNER = 'admin/partner';
export const POST_CONTACT = 'contact';