import { combineReducers } from "redux";
import createPartnerReducer from "./createPartnerReducer";
import getVehicleTypesReducer from "./getVehicleTypesReducer";
import getCityListReducer from "./getCityListReducer";
import registerReducer from "./registerReducer";
import loginReducer from "./loginReducer";
import createBookingReducer from './createBookingReducer';
import getBookingInfoReducer from './getBookingInfoRedicer';
import createCityReducer from './createCityReducer';
import updateCityReducer from './updateCityReducer';
import createVehicleReducer from './createVehicleReducer';
import updateVehicleReducer from './updateVehicleReducer';
import partnersReducer from './partnersReducer';
import partnerReducer from './partnerReducer';
import bookingsReducer from './bookingsReducer';
import bookingReducer from './bookingReducer';
import userBookingsReducer from './userBookingsReducer';
import postContact from './postContactReducer';
import sendOtp from './otpSendReducer';
import verifyOtp from './verifyOtpReducer';
import subscribe from './subscribeReducer';

const reducers = combineReducers({
  createPartner: createPartnerReducer,
  vehicleTypes: getVehicleTypesReducer,
  cityList: getCityListReducer,
  register: registerReducer,
  login: loginReducer,
  createBooking: createBookingReducer,
  bookingInfo: getBookingInfoReducer,
  createCity: createCityReducer,
  updateCity: updateCityReducer,
  createVehicle: createVehicleReducer,
  updateVehicle: updateVehicleReducer,
  partners: partnersReducer,
  partner: partnerReducer,
  bookings: bookingsReducer,
  booking: bookingReducer,
  userBookings: userBookingsReducer,
  postContact: postContact,
  sendOtp: sendOtp,
  verifyOtp: verifyOtp,
  subscribe: subscribe,
})

export default reducers;