import { Alert } from "@coreui/coreui";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { postContactReset, postContact } from "../../redux/actions/postContactAction";

function Contact({ bgColor, result, postContact, postContactReset }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("")

  useEffect(() => {
    postContactReset();
  }, [])

  useEffect(() => {

    if (result.postContact.success) {
      console.log("cola", result.postContact.success)
      setSuccess(result.postContact.payload.message)
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
    }
  }, [result])

  function submitForm() {
    if (name && phone && email && message) {
      postContact({
        name,
        phone,
        email,
        message
      })
      setError("")
    } else {
      console.log("err");
      setError("please fill all fields!");
    }
  }
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>Contact With Us</h2>
                <p>
                  It's very easy to get in touch with us. Just use the contact
                  form or pay us a visit for a coffee at the office.
                </p>
              </div>
              <div className="footer-address">
                <h6>
                  <strong>Head Office</strong>
                </h6>
                <p>Ranchi, Jharkhand, India</p>
                <ul>
                  <li>
                    <span>Phone: +91 7091 997222</span>
                  </li>
                  <li className="list-inline-item">
                    Whatsapp: <a href="https://api.whatsapp.com/send?phone=+917091997222&text=Hey%2C+Can+you+please+share+estimate%21%21" target="_blank" title="Whatsapp">
                      <img src="assets/img/wsp-icon.png" style={{ width: 30, height: 30, marginBottom: -10 }}></img>
                    </a>
                  </li>
                  <li>
                    <span>
                      Email :
                      <a href="mailto:contact@athravtechnologies.com">
                        {' contact@athravtechnologies.com'}
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <div
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                <h5>Reach us quickly</h5>
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        required="required"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        required="required"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                {error &&
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <div class="alert alert-danger" role="alert">
                        {error}
                      </div>
                    </div>
                  </div>
                }
                {success &&
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <div class="alert alert-success" role="alert">
                        {success}
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                      onClick={() => submitForm()}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    result: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    postContactReset: () => dispatch(postContactReset()),
    postContact: (data) => dispatch(postContact(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
