
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

export default function AboutUs() {
  return (
    <>
      <Layout>
        <Navbar />
        <div style={{ marginLeft: '100px', marginRight: '100px', marginTop: '100px' }}>
          <div class="page" title="Page 3">
            <div class="section">
              <div class="layoutArea">
                <div class="column" style={{ marginBottom: 300 }}>
                  <h2>About Us</h2>
                  <p>Last updated: [26th March, 2023]</p>
                  <p>WheelPeGo provides efficient logistics solutions to businesses of all sizes. Our services include transportation, warehousing, management, order fulfilment, packaging, reverse logistics, and consulting services.</p>
                  <p>At our core, we believe logistics should be simple, efficient, and cost-effective. With our customer-centric approach, we take the time to understand our client's unique needs and tailor our services accordingly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer space />
      </Layout>
    </>
  );
}






