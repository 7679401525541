import React from "react";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PartnerForm from "../../components/others/PartnerForm";
import Features from "../../components/features/Features";
import Footer from "../../components/layout/footer/Footer";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import Screenshot from "../../components/screenshot/Screenshot";

export default function SignupPage() {
  return (
    <>
      <Navbar />
      <PartnerForm />
      <Features showButton={true} />
      {/* <FeatureImgThree /> */}
      {/* <Screenshot hasBg /> */}
      {/* <Footer space /> */}
      <div className="row" style={{ marginTop: '50px', marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div title="Page 1" className="col-md-10">
          <h2>Driver Qualifications</h2>
          <p>To be considered for a driver&apos;s position with our company, applicants must meet the following qualifications:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Valid Commercial Driver&apos;s Licence (CDL) with endorsements</li>
            <li>Clean driving record with no major violations in the past 10 years</li>
            <li>Ability to pass a pre-employment background check</li>
            <li>Willingness to adhere to all traffic laws and company safety protocol</li>
            <li>Ability to operate specialized equipment and handle shipment in a safe and efficient manner</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Driver’s Qualities (general)</h2>
          <p><b>Age:</b> The driver must be at least 18 years of age.</p>
          <p><b>Nationality:</b> The driver must be legally authorised to work in India.</p>
          <p><b>Driving Licence:</b> The driver must have a valid licence for the type of vehicle they will be operating and must maintain a clean driving record.</p>
          <p><b>Experience:</b> The driver should have experience driving similar vehicles and be comfortable navigating through various weather and road conditions.</p>
          <p><b>Physical Fitness:</b> The driver must be physically fit and able to lift and move heavy cargo as needed.</p>
          <p><b>Communication Skills:</b> The driver should have strong communication skills and should be able to communicate effectively with customers, colleagues, and supervisors.</p >
          <p><b>Time Management:</b> The driver should be able to manage their time effectively and should be able to meet deadlines and schedules for delivery.</p >
          <p><b>Dependability:</b> The driver should be dependable and able to work independently with minimal supervision.</p >
          <p><b>Professionalism:</b> The driver should have a professional demeanour and should be able to represent the company positively.</p >
        </div>
        <div className="col-md-1"></div>
      </div>
      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Roles & Responsibility</h2>
          <p>WheelPeGo is committed to providing our drivers with the resources to succeed, including top-notch technology, ongoing training, and competitive compensation. As a driver with WheelPeGo, you'll follow:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Transporting goods across the country and ensuring that you meet delivery timelines</li>
            <li>Keeping accurate records of deliveries and ensuring that all necessary paperwork is done</li>
            <li>Adhering to all traffic laws and company safety protocols to ensure the safety of yourself and others on the road</li>
            <li>Operating specialised equipment and handling cargo in a safe and efficient manner</li>
            <li>Communicating with dispatchers and other team members to ensure that you complete all routes in a timely and efficient manner</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Benefits and Compensation</h2>
          <p>We're proud to offer our drivers a comprehensive benefits and compensation package that includes:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Competitive pay rates based on experience and performance</li>
            <li>Insurance, if any</li>
            <li>Performance-based bonuses and incentives</li>
            <li>Ongoing training and development opportunities</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Safety Protocols</h2>
          <p>WheelPeGo is committed to keeping our drivers safe on the road. We require all drivers to follow these safety protocols:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Regular vehicle maintenance to ensure that all equipment is in good working order</li>
            <li>Adherence to all traffic laws and company safety protocols</li>
            <li>Use of safety equipment such as seat belts and reflective clothing</li>
            <li>Participation in ongoing safety training and development programs</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Safety Protocols</h2>
          <p>WheelPeGo is committed to keeping our drivers safe on the road. We require all drivers to follow these safety protocols:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Regular vehicle maintenance to ensure that all equipment is in good working order</li>
            <li>Adherence to all traffic laws and company safety protocols</li>
            <li>Use of safety equipment such as seat belts and reflective clothing</li>
            <li>Participation in ongoing safety training and development programs</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>


      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Technology and Equipment</h2>
          <p>We use the latest technology and equipment to ensure that the drivers have everything they need to succeed, which includes:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>GPS tracking devices to help drivers navigate routes and stay on schedule</li>
            <li>Specialised lorries/vehicles designed to handle a variety of goods</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Training and Development</h2>
          <p>We're committed to helping our drivers improve their skills and advance in their careers. We offer the following training and development opportunities:</p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>On-the-job training to help drivers become proficient in using specialised equipment and technology</li>
            <li>Continuing education programs to help drivers stay up-to-date with the latest industry trends and regulations</li>
            <li>Professional development opportunities</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>

      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>How to Apply?</h2>
          <p>To be a future WheelPeGo Driver, please visit our website and submit your application materials.
            We'll review your application and get back to you as soon as possible to discuss the next steps in the hiring process.</p>
        </div>
        <div className="col-md-1"></div>
      </div>


      <div className="row" style={{ marginBottom: '50px' }}>
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <h2>Driver Terms and Conditions</h2>
          <p></p>
          <ol style={{ listStyleType: 'inherit' }}>
            <li>Eligibility: To be eligible to work as a driver for WheelPeGo, you must possess a valid commercial driver's licence (CDL) with endorsements, have a clean driving record with no major violations in the past 10 years, and be able to pass a pre-employment screening and background check</li>
            <li>Driver Responsibilities: As a driver for WheelPeGo, you will transport goods across the country, keep accurate records of deliveries, adhere to all traffic laws and company safety protocols, operate specialised equipment and handle everything in a safely and efficiently, and communicate with dispatchers and other team members to ensure that all routes are completed in a timely and efficient manner.</li>
            <li>Compensation: Drivers will be compensated based on experience and performance. Besides competitive pay rates, drivers may be eligible for performance-based bonuses and incentives.</li>
            <li>Safety Protocols: All drivers are required to follow company safety protocols, including regular vehicle maintenance, adherence to all traffic laws, use of safety
              You'll be asked to provide a current resume, a copy of your CDL, and other relevant documentation. equipment such as seat belts and reflective clothing, and participation in ongoing safety training and development programs.</li>
            <li>Confidentiality: As a driver for WheelPeGo, you may be exposed to confidential information related to our clients and their shipments. You agree to maintain the confidentiality of this information and not to disclose it to any third parties without prior written consent from the company.</li>
            <li>Termination: WheelPeGo reserves the right to terminate your employment as a driver for any reason, including but not limited to violation of company policies or safety protocols, failure to meet performance expectations or changes in business needs.</li>
            <li>Non-Competition: During the term of your employment with WheelPeGo and for one year thereafter, you agree not to directly or indirectly compete with WheelPeGo by engaging in transportation services to clients who are or were clients of WheelPeGo during your employment with the company.</li>
            <li>Governing Law: These terms and conditions shall be governed by and construed under the laws of the state in which WheelPeGo is located.</li>
            <li>Payment Terms: Payment for driver services will be made monthly via direct deposit or another payment method designated by the company. Drivers will be responsible for submitting accurate and timely records of their hours worked, deliveries made, and expenses incurred and complying with all company policies and procedures related to payment and expense reimbursement.</li>
            <li>General Covenants: In addition to the specific terms and conditions set forth above, you agree to the following covenants:</li>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <ul style={{ listStyleType: 'inherit' }}>
                  <li>You will comply with all applicable laws and regulations related to your employment as a driver, including but not limited to those related to safety, employment, and transportation.</li>
                  <li>You will conduct yourself professionally and courteously at all times when representing WheelPeGo and not engage in conduct that could harm the reputation or business interests of the company.</li>
                  <li>You will report any accidents, incidents, or safety concerns to your supervisor as soon as possible and will cooperate fully with any investigations or reviews related to such matters.</li>
                  <li>You will maintain accurate and complete records of your work activities and expenses and promptly report any discrepancies or errors to your supervisor.</li>
                  <li>You will maintain the confidentiality of all company information and trade secrets and will not disclose such information to any third parties without prior written consent from the company.</li>
                  <li>You will comply with all company policies and procedures related to safety, security, expense reimbursement, and other matters as may be established from time to time by WheelPeGo.</li>
                </ul>
              </div>
            </div>
            <li>Limitation of Liability: WheelPeGo shall not be liable for any loss or damage to shipment, property, or equipment resulting from any act or omission by the driver, including but not limited to negligent or reckless driving, failure to secure cargo, or failure to follow company safety protocols. The driver shall indemnify and hold WheelPeGo harmless from any and all claims, damages, or losses arising from such acts or omissions.</li>
          </ol>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer space />

    </>
  );
}
