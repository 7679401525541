import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { createPartner, createPartnerReset } from "../../redux/actions/createPartnerAction";
import { getCityList, getCityListReset } from "../../redux/actions/getCityListAction";
import { getVehicleTypes, getVehicleTypesReset } from "../../redux/actions/getVehicleTypesAction";
import { sendOtp, sendOtpReset } from "../../redux/actions/otpSendAction";
import { verifyOtp, verifyOtpReset } from "../../redux/actions/verifyOtpAction";
import { connect } from 'react-redux';

function PartnerForm({
  result,
  createPartner,
  getCityList,
  getCityListReset,
  getVehicleTypes,
  getVehicleTypesReset,
  sendOtp,
  sendOtpReset,
  verifyOtp,
  verifyOtpReset
}) {

  useEffect(() => {
    getCityListReset();
    getVehicleTypesReset();
    getCityList();
    getVehicleTypes();
    sendOtpReset();
    verifyOtpReset();
  }, []);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(0);
  const [email, setEmail] = useState("");
  const [city, setCity] = useState(0);
  const [cityError, setCityError] = useState(0);
  const [accessible, setAccessible] = useState("");
  const [accessibleError, setAccessibleError] = useState(0);
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleTypeError, setVehicleTypeError] = useState(0);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberError, setVehicleNumberError] = useState(0);
  const [gps, setGps] = useState(0);
  const [gpsError, setGpsError] = useState(0);
  const [availability, setAvailability] = useState(0);
  const [availabilityError, setAvailabilityError] = useState(0);
  const [other, setOther] = useState(0);
  const [otherError, setOtherError] = useState(0);
  const [agree, setAgree] = useState(0);
  const [agreeError, setAgreeError] = useState(0);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');

  const [cityData, setCityData] = useState(null);
  const [vehicelTypeData, setVehicelTypeData] = useState(null);

  const [cities, setCities] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);

  const [error, setError] = useState(0);
  const [message, setMessage] = useState("");

  const [success, setSuccess] = useState(0);

  const [stage, setStage] = useState(0);


  const agreeChange = () => {
    if (agree === 1) {
      setAgree(0);
    } else {
      setAgree(1);
    }
  }

  const sendOtpNow = () => {
    sendOtp(phone);
  }

  const verifyOtpNow = () => {
    if (otp.length == 6 && phone) {
      verifyOtp(phone, otp)
    } else {
      setOtpError("error")
    }
  }

  const createPartnerNow = () => {
    setNameError(0);
    setPhoneError(0);
    setGpsError(0);
    setCityError(0);
    setAccessibleError(0);
    setAvailabilityError(0);
    setVehicleNumberError(0);
    setVehicleTypeError(0);
    setOtherError(0);
    setAgreeError(0);
    console.log(name, phone, city, accessible, vehicleNumber, vehicleType, gps, availability, other, agree);
    if (name && phone && city != 0 && accessible != 0 && vehicleNumber && vehicleType != 0 && gps != 0 && availability != 0 && other != 0 && agree != 0) {
      sendOtpNow();
      setStage(1);
    } else {
      if (!name) {
        setNameError(1);
      }
      if (!phone) {
        setPhoneError(1);
      }
      if (!vehicleNumber) {
        setVehicleNumberError(1);
      }
      if (city == 0) {
        setCityError(1);
      }
      if (accessible == 0) {
        setAccessibleError(1);
      }
      if (availability == 0) {
        setAvailabilityError(1);
      }
      if (gps == 0) {
        setGpsError(1);
      }
      if (vehicleType == 0) {
        setVehicleTypeError(1);
      }
      if (other == 0) {
        setOtherError(1);
      }
      if (agree == 0) {
        setAgreeError(1);
      }
      setError(1);
      setMessage("Some issue in form");
    }
  }


  const createPartnerCall = () => {
    createPartner({
      name: name,
      email: email,
      city_id: city,
      accessible: accessible,
      vehicle_type_id: vehicleType,
      vehicle_number: vehicleNumber,
      phone: phone,
      gps: gps,
      availability: availability,
      other_company: other
    });
  }

  useEffect(() => {
    if (result.createPartner.success) {
      setSuccess(1);
      setTimeout(function () {
        window.location.href = '/';
      }, 2000);
    }
    if (result.createPartner.error) {
      console.log(result.createPartner.payload.response.data.message)
      setError(result.createPartner.payload.response.data.message)
    }
  }, [result.createPartner])


  useEffect(() => {
    if (result.cityList.success) {
      console.log(result.cityList.payload.data)
      setCities(result.cityList.payload.data);
    }
  }, [result.cityList])


  useEffect(() => {
    if (result.vehicleTypes.success) {
      setVehicleTypes(result.vehicleTypes.payload.data);
    }
  }, [result.vehicleTypes])


  useEffect(() => {
    console.log("ERRMSG -", result.verifyOtp)
    if (result.verifyOtp.success) {
      createPartnerCall();
      verifyOtpReset();
    }
    if (result.verifyOtp.error) {
      console.log("ERRMSG -", result.verifyOtp.payload)
      setError(1);
      setMessage(result.verifyOtp.payload.message);
      verifyOtpReset();
    }

  }, [result.verifyOtp])

  useEffect(() => {
    if (result.sendOtp.success) {
      // createPartnerCall();
      setError(0);
      setMessage("");
      sendOtpReset();
    }
    if (result.sendOtp.error) {
      setError(1);
      setMessage(result.sendOtp.error.payload);
      sendOtpReset();
    }
  }, [result.sendOtp])



  return (
    <>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          background:
            "url('assets/img/background.jpg')no-repeat center top / contain",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-5 pt-lg-0">
            <div className="col-md-4 col-lg-4">
              <div className="hero-content-left text-white">
                <h1 className="text-white">Become a partner</h1>
                <p className="lead">
                  Join our elite team of skilled drivers and let’s deliver goods swiftly and securely across the nation!
                </p>
              </div>
            </div>
            <div className="col-md-8 col-lg-8">
              <div className="card login-signup-card shadow-lg mb-0">
                <div className="card-body px-md-5 py-5">

                  <div className="mb-5">
                    <h6 className="h3">Apply now</h6>
                    <p className="text-muted mb-0">
                    </p>
                  </div>

                  <div className="login-signup-form row">
                    <div className="form-group col-md-12">
                      <label className="pb-1">Your Name/Company Name</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-user color-primary"></span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your/company name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      {nameError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Name not valid! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Email Address</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-email color-primary"></span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="name@address.com"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Phone</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-mobile color-primary"></span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="9XXXXXXXXX"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      {phoneError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Phone not valid! </span>
                      }
                    </div>

                    <p className="text-muted mb-0 col-md-12" style={{ fontSize: 20, fontWeignt: '700' }}>
                      <b>
                        {'Vehicle Information'}
                      </b>
                    </p>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Vehicle Number</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-truck color-primary"></span>
                        </div>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="XXXX XXX XXXX"
                          onChange={(e) => setVehicleNumber(e.target.value)}
                        />
                      </div>
                      {vehicleNumberError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Vehicle number not valid! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Vehicle type</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-truck color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setVehicleType(e.target.value)}>
                          <option value={0}>Select Vehicle type</option>
                          {vehicleTypes.map(({ id, name }, index) => <option value={id} >{name}</option>)}
                        </select>
                      </div>
                      {vehicleTypeError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select Vehicle type! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">City</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-map color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setCity(e.target.value)}>
                          <option value={0}>Select City</option>
                          {cities.map(({ id, name }, index) => <option value={id} >{name}</option>)}
                        </select>
                      </div>
                      {cityError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select City! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Accessible</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-map color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setAccessible(e.target.value)}>
                          <option >Choose option</option>
                          <option value={1}>Whithin city</option>
                          <option value={2}>Outer</option>
                          <option value={3}>All</option>
                        </select>
                      </div>
                      {accessibleError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select accessible! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Have GPS?</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-map color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setGps(e.target.value)}>
                          <option value={0}>Choose option</option>
                          <option value={1}>Yes</option>
                          <option value={2}>No</option>
                        </select>
                      </div>
                      {gpsError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select gps! </span>
                      }
                    </div>

                    <div className="form-group col-md-6">
                      <label className="pb-1">Available</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-map color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setAvailability(e.target.value)}>
                          <option value={0}>Choose option</option>
                          <option value={1}>Day</option>
                          <option value={2}>Night</option>
                          <option value={3}>24 Hour</option>
                        </select>
                      </div>
                      {availabilityError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select Availability! </span>
                      }
                    </div>

                    <div className="form-group col-md-12">
                      <label className="pb-1">Serve other company?</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-map color-primary"></span>
                        </div>
                        <select className="nice-select form-control" onChange={(e) => setOther(e.target.value)}>
                          <option value={0}>Choose option</option>
                          <option value={1}>Yes</option>
                          <option value={2}>No</option>
                        </select>
                      </div>
                      {otherError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Select option! </span>
                      }
                    </div>

                    {success == 1 &&
                      <div className="row">
                        <alert className="alert-success alert-padding"> Thankyou for submitting, We will contact you soon. </alert>
                      </div>
                    }

                    <div className="my-4">
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="check-terms"
                          onClick={() => agreeChange()}
                        />
                        <label
                          className="custom-control-label"
                          for="check-terms"
                        >
                          I agree to the <a href="#/">terms and conditions</a>
                        </label>
                      </div>
                      {agreeError == 1 &&
                        <span className="error-text" style={{ color: 'red' }}> Check terms and conditions! </span>
                      }
                    </div>

                    {stage === 1 &&
                      <div className="form-group col-md-12">
                        <label className="pb-1">ENTER OTP</label>
                        <div className="input-group input-group-merge">
                          <div className="input-icon">
                            <span className="ti-mobile color-primary"></span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter OTP"
                            maxLength={6}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                        {otpError &&
                          <span className="error-text" style={{ color: 'red' }}> {otpError} </span>
                        }
                      </div>
                    }



                    <div className="form-group col-md-12">
                      {error == 1 &&
                        <div className="row" style={{ borderRadious: '20px !important' }}>
                          <alert className="alert-danger alert-padding" style={{ padding: 10, border: '1px solid red' }}> {message} </alert>
                        </div>
                      }
                    </div>

                    {stage === 0 &&
                      <button
                        className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                        onClick={() => createPartnerNow()}>
                        Send OTP
                      </button>
                    }

                    {stage === 1 &&
                      <button
                        className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                        onClick={() => verifyOtpNow()}>
                        Verify OTP
                      </button>
                    }

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="bottom-img-absolute">
          <img
            src="assets/img/wave-shap.svg"
            alt="wave shape"
            className="img-fluid"
          />
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    result: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createPartner: (data) => dispatch(createPartner(data)),
    createPartnerReset: () => dispatch(createPartnerReset()),
    getCityList: () => dispatch(getCityList()),
    getCityListReset: () => dispatch(getCityListReset()),
    getVehicleTypes: () => dispatch(getVehicleTypes()),
    getVehicleTypesReset: () => dispatch(getVehicleTypesReset()),
    sendOtp: (phone) => dispatch(sendOtp(phone)),
    sendOtpReset: () => dispatch(sendOtpReset()),
    verifyOtp: (phone, otp) => dispatch(verifyOtp(phone, otp)),
    verifyOtpReset: () => dispatch(verifyOtpReset())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm)
