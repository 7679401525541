import {GET_BOOKING_INFO_SUCCESS, GET_BOOKING_INFO_LOADING, GET_BOOKING_INFO_ERROR, GET_BOOKING_INFO_RESET} from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
    switch (action.type) {
        case GET_BOOKING_INFO_SUCCESS:
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            payload: action.payload
          };
        case GET_BOOKING_INFO_ERROR:
          return {
            ...state,
            success: false,
            error: true,
            loading: false,
            payload: action.payload
          };
        case GET_BOOKING_INFO_LOADING:
          return {
            ...state,
            success: false,
            error: false,
            loading: true,
            payload: null
          };
        case GET_BOOKING_INFO_RESET:
          return {
            ...state,
            success: false,
            error: false,
            loading: false,
            payload: null
          };
        default:
          return state;
    }
}

export default index;