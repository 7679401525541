import { Link } from "react-router-dom";

export default function LatestNewsOne({ bgColor, light }) {
  return (
    <>
      <section id="blog" className={`our-blog-section ptb-100 ${bgColor}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-heading mb-5">
                <h2 className={`${light ? "text-black" : "text-white"}`}>
                  Customer Review
                </h2>
                <p className="lead">
                  Wheel Pe Go Core Principle Is Life is to keep Custoer Happy
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-primary">
                  WPG User
                </span>
                <img
                  src="assets/img/review/review-1.png"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Jun28 21, 2023</li>
                      <li className="list-inline-item">
                        <span>45</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">
                      Rajesh shares his experince
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    The company was very responsive to my inquiries, and the drivers were professional. My cargo was delivered on time and in good condition.

                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  WPG User
                </span>
                <img
                  src="assets/img/review/review-2.jpg"
                  className="card-img-top position-relative"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">July 2, 2023</li>
                      <li className="list-inline-item">
                        <span>30</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">Sumit Shares His WPG Story</Link>
                  </h3>
                  <p className="card-text text-dark">
                    "I recently used Wheel Pe Go Service to ship a large piece of furniture from Ranchi to Kolkata. I was very impressed with the quality of the service. I would definitely recommend this company to others."

                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-info">
                  WPG User
                </span>
                <img
                  src="assets/img/blog/010.jpg"
                  className="card-img-top"
                  alt="blog"
                />
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">July 15, 2023</li>
                      <li className="list-inline-item">
                        <span>41</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className="h5 card-title">
                    <Link to="/blog-details">Mayank Shares His WPG Story</Link>
                  </h3>
                  <p className="card-text text-dark">
                    he price of the service was very reasonable, and there were no hidden fees.

                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
