export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_LOADING = 'CREATE_PARTNER_LOADING';
export const CREATE_PARTNER_ERROR = 'CREATE_PARTNER_ERROR';
export const CREATE_PARTNER_RESET = 'CREATE_PARTNER_RESET';

export const GET_VEHICLE_TYPE_SUCCESS = 'GET_VEHICLE_TYPE_SUCCESS';
export const GET_VEHICLE_TYPE_LOADING = 'GET_VEHICLE_TYPE_LOADING'
export const GET_VEHICLE_TYPE_ERROR = 'GET_VEHICLE_TYPE_ERROR';
export const GET_VEHICLE_TYPE_RESET = 'GET_VEHICLE_TYPE_RESET';

export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_LOADING = 'GET_CITY_LIST_LOADING';
export const GET_CITY_LIST_ERROR = 'GET_CITY_LIST_ERROR';
export const GET_CITY_LIST_RESET = 'GET_CITY_LIST_RESET';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_RESET = 'REGISTER_RESET';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';

export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_ERROR = 'CREATE_BOOKING_ERROR';
export const CREATE_BOOKING_LOADING = 'CREATE_BOOKING_LOADING';
export const CREATE_BOOKING_RESET = 'CREATE_BOOKING_RESET';

export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_ERROR = 'GET_BOOKING_ERROR';
export const GET_BOOKING_LOADING = 'GET_BOOKING_LOADING';
export const GET_BOOKING_RESET = 'GET_BOOKING_RESET';

export const GET_BOOKING_INFO_SUCCESS = 'GET_BOOKING_INFO_SUCCESS';
export const GET_BOOKING_INFO_ERROR = 'GET_BOOKING_INFO_ERROR';
export const GET_BOOKING_INFO_LOADING = 'GET_BOOKING_INFO_LOADING';
export const GET_BOOKING_INFO_RESET = 'GET_BOOKING_INFO_RESET';

export const ADMIN_CREATE_CITY_SUCCESS = 'ADMIN_CREATE_CITY_SUCCESS';
export const ADMIN_CREATE_CITY_ERROR = 'ADMIN_CREATE_CITY_ERROR';
export const ADMIN_CREATE_CITY_LOADING = 'ADMIN_CREATE_CITY_LOADING';
export const ADMIN_CREATE_CITY_RESET = 'ADMIN_CREATE_CITY_RESET';

export const ADMIN_UPDATE_CITY_SUCCESS = 'ADMIN_UPDATE_CITY_SUCCESS';
export const ADMIN_UPDATE_CITY_ERROR = 'ADMIN_UPDATE_CITY_ERROR';
export const ADMIN_UPDATE_CITY_LOADING = 'ADMIN_UPDATE_CITY_LOADING';
export const ADMIN_UPDATE_CITY_RESET = 'ADMIN_UPDATE_CITY_RESET';

export const VEHICLE_CREATE_SUCCESS = 'VEHICLE_CREATE_SUCCESS';
export const VEHICLE_CREATE_ERROR = 'VEHICLE_CREATE_ERROR';
export const VEHICLE_CREATE_LOADING = 'VEHICLE_CREATE_LOADING';
export const VEHICLE_CREATE_RESET = 'VEHICLE_CREATE_RESET';

export const VEHICLE_UPDATE_SUCCESS = 'VEHICLE_UPDATE_SUCCESS';
export const VEHICLE_UPDATE_ERROR = 'VEHICLE_UPDATE_ERROR';
export const VEHICLE_UPDATE_LOADING = 'VEHICLE_UPDATE_LOADING';
export const VEHICLE_UPDATE_RESET = 'VEHICLE_UPDATE_RESET';

export const PARTNERS_SUCCESS = 'PARTNERS_SUCCESS';
export const PARTNERS_ERROR = 'PARTNERS_ERROR';
export const PARTNERS_LOADING = 'PARTNERS_LOADING';
export const PARTNERS_RESET = 'PARTNERS_RESET';

export const PARTNER_SUCCESS = 'PARTNER_SUCCESS';
export const PARTNER_ERROR = 'PARTNER_ERROR';
export const PARTNER_LOADING = 'PARTNER_LOADING';
export const PARTNER_RESET = 'PARTNER_RESET';

export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS';
export const BOOKINGS_ERROR = 'BOOKINGS_ERROR';
export const BOOKINGS_LOADING = 'BOOKINGS_LOADING';
export const BOOKINGS_RESET = 'BOOKINGS_RESET';

export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const BOOKING_LOADING = 'BOOKING_LOADING';
export const BOOKING_RESET = 'BOOKING_RESET';

export const PAYMENT_CONFIRM_SUCCESS = 'PAYMENT_CONFIRM_SUCCESS';
export const PAYMENT_CONFIRM_ERROR = 'PAYMENT_CONFIRM_ERROR';
export const PAYMENT_CONFIRM_LOADING = 'PAYMENT_CONFIRM_LOADING';
export const PAYMENT_CONFIRM_RESET = 'PAYMENT_CONFIRM_RESET';

export const USER_BOOKINGS_SUCCESS = 'USER_BOOKINGS_SUCCESS';
export const USER_BOOKINGS_ERROR = 'USER_BOOKINGS_ERROR';
export const USER_BOOKINGS_LOADING = 'USER_BOOKINGS_LOADING';
export const USER_BOOKINGS_RESET = 'USER_BOOKINGS_RESET';

export const USER_CONTACT_SUCCESS = 'USER_CONTACT_SUCCESS';
export const USER_CONTACT_ERROR = 'USER_CONTACT_ERROR';
export const USER_CONTACT_LOADING = 'USER_CONTACT_LOADING';
export const USER_CONTACT_RESET = 'USER_CONTACT_RESET';

export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';
export const SEND_OTP_LOADING = 'SEND_OTP_LOADING';
export const SEND_OTP_RESET = 'SEND_OTP_RESET';

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR';
export const VERIFY_OTP_LOADING = 'VERIFY_OTP_LOADING';
export const VERIFY_OTP_RESET = 'VERIFY_OTP_RESET';

export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_ERROR = 'SUBSCRIBE_ERROR';
export const SUBSCRIBE_LOADING = 'SUBSCRIBE_LOADING';
export const SUBSCRIBE_RESET = 'SUBSCRIBE_RESET';