import { BOOKING_SUCCESS, BOOKING_ERROR, BOOKING_LOADING, BOOKING_RESET } from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
    switch (action.type) {
        case BOOKING_SUCCESS:
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            payload: action.payload
          };
        case BOOKING_ERROR:
          return {
            ...state,
            success: false,
            error: true,
            loading: false,
            payload: action.payload
          };
        case BOOKING_LOADING:
          return {
            ...state,
            success: false,
            error: false,
            loading: true,
            payload: null
          };
        case BOOKING_RESET:
          return {
            ...state,
            success: false,
            error: false,
            loading: false,
            payload: null
          };
        default:
          return state;
    }
}

export default index;