export const USER_ID = 'USER_ID';
export const USER_EMAIL = 'USER_EMAIL';
export const USER_PHONE = 'USER_PHONE';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_FIRST_NAME = 'USER_FIRST_NAME';
export const USER_LAST_NAME = 'USER_LAST_NAME';
export const USER_TYPE = 'USER_TYPE';

export const BASE_URL = 'https://api.wheelpego.com/';
// export const BASE_URL = 'http://localhost:3000/';

export const GOOGLE_API_KEY = "AIzaSyANcsd7-xYO2us8cYii4CXBHzupSTo8Dzs"