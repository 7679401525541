import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";


export default function Events() {
  return (
    <>
      <Layout>
        <Navbar />
        <div style={{ marginLeft: '100px', marginRight: '100px', marginTop: '100px', marginBottom: 350 }}>
          <div class="page" title="Page 3">
            <div class="section">
              <div class="layoutArea">
                <div class="column">
                  <h2>Upcoming Events</h2>
                  <p>No upcoming events.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer space />
      </Layout>
    </>
  );
}
