import {CREATE_BOOKING_SUCCESS, CREATE_BOOKING_ERROR, CREATE_BOOKING_LOADING, CREATE_BOOKING_RESET} from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
    switch (action.type) {
        case CREATE_BOOKING_SUCCESS:
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            payload: action.payload
          };
        case CREATE_BOOKING_ERROR:
          return {
            ...state,
            success: false,
            error: true,
            loading: false,
            payload: action.payload
          };
        case CREATE_BOOKING_LOADING:
          return {
            ...state,
            success: false,
            error: false,
            loading: true,
            payload: null
          };
        case CREATE_BOOKING_RESET:
          return {
            ...state,
            success: false,
            error: false,
            loading: false,
            payload: null
          };
        default:
          return state;
    }
}

export default index;