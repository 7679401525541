import { HTTP, OTP_VERIFY } from '../../utility/api';
import { VERIFY_OTP_SUCCESS, VERIFY_OTP_ERROR, VERIFY_OTP_LOADING, VERIFY_OTP_RESET } from '../types';

export const fetchingRequest = () => ({
  type: VERIFY_OTP_LOADING
})

export const fetchingError = (error) => ({
  type: VERIFY_OTP_ERROR,
  payload: error
})

export const fetchingSuccess = (data) => ({
  type: VERIFY_OTP_SUCCESS,
  payload: data
})

export const verifyOtpReset = () => ({
  type: VERIFY_OTP_RESET
})

export function verifyOtp(phone, OTP) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(OTP_VERIFY, { phone, otp: OTP })
      .then((response) => {
        if (response.data.success) {
          dispatch(fetchingSuccess(response.data));
        } else {
          console.log("111", response.data);
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        console.log("123", error.response?.data);
        dispatch(fetchingError(error.response?.data))
      });
  }
};