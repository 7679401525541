import { CREATE_PARTNER_SUCCESS, CREATE_PARTNER_LOADING, CREATE_PARTNER_ERROR, CREATE_PARTNER_RESET } from '../types';
import { HTTP, POST_CREATE_PARTNER } from '../../utility/api';

export const fetchingRequest = () => ({
  type: CREATE_PARTNER_LOADING
});
export const fetchingError = (error) => ({
  type: CREATE_PARTNER_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: CREATE_PARTNER_SUCCESS,
  payload: data
})
export const createPartnerReset = () => ({
  type: CREATE_PARTNER_RESET
})

export function createPartner(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(POST_CREATE_PARTNER, data)
      .then((response) => {
        if (response.data.success) {
            dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
    });
  }
};