import { USER_CONTACT_SUCCESS, USER_CONTACT_ERROR, USER_CONTACT_LOADING, USER_CONTACT_RESET, USER_BOOKINGS_ERROR } from '../types';
import { HTTP, POST_CONTACT } from '../../utility/api';

export const fetchingRequest = () => ({
  type: USER_CONTACT_LOADING
});
export const fetchingError = (error) => ({
  type: USER_BOOKINGS_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: USER_CONTACT_SUCCESS,
  payload: data
})
export const postContactReset = () => ({
  type: USER_CONTACT_RESET
})

export function postContact(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(POST_CONTACT, data)
      .then((response) => {
        if (response.data.success) {
          dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
      });
  }
};