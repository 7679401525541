
import { VEHICLE_UPDATE_SUCCESS, VEHICLE_UPDATE_ERROR, VEHICLE_UPDATE_LOADING, VEHICLE_UPDATE_RESET } from '../types';
export const defaultState = {
  payload: null,
  loading: false,
  error: false,
  success: false
};

const index = (state = defaultState, action) => {
    switch (action.type) {
        case VEHICLE_UPDATE_SUCCESS:
          return {
            ...state,
            success: true,
            error: false,
            loading: false,
            payload: action.payload
          };
        case VEHICLE_UPDATE_ERROR:
          return {
            ...state,
            success: false,
            error: true,
            loading: false,
            payload: action.payload
          };
        case VEHICLE_UPDATE_LOADING:
          return {
            ...state,
            success: false,
            error: false,
            loading: true,
            payload: null
          };
        case VEHICLE_UPDATE_RESET:
          return {
            ...state,
            success: false,
            error: false,
            loading: false,
            payload: null
          };
        default:
          return state;
    }
}

export default index;