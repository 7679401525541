import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePassword from "../components/others/ChangePassword";
import ComingSoon from "../components/others/ComingSoon";
import LoginTwo from "../components/others/LoginTwo";
import NotFound from "../components/others/NotFound";
import PasswordReset from "../components/others/PasswordReset";
import ThankYou from "../components/others/ThankYou";
import Login from "../pages/SignUp";
import HomeOne from "../themes/index-1/HomeOne";
import HomeTwo from "../themes/index-2/HomeTwo";
import HomeThree from "../themes/index-3/HomeThree";
import Partner from "../pages/supplier/index";
import HomeFour from "../themes/index-4/HomeFour";
import HomeFive from "../themes/index-5/HomeFive";
import HomeSix from "../themes/index-6/HomeSix";
import HomeSeven from "../themes/index-7/HomeSeven";
import HomeEight from "../themes/index-8/HomeEight";
import OurTeam from "../pages/OurTeam";
import TeamDetails from "../pages/TeamDetails";
import Download from "../pages/Download";
import Review from "../pages/Review";
import Faq from "../pages/Faq";
import SignupTwo from "../components/others/SignupTwo";
import SignupPage from "../pages/SignupPage";
import BlogGrid from "../pages/BlogGrid";
import BlogWithSidebar from "../pages/BlogWithSidebar";
import BlogDetails from "../pages/BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUs from "../pages/AboutUs";
import Events from "../pages/Events";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<HomeThree />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/events" element={<Events />} />
          {/* <Route path="/home-two" element={<HomeTwo />} /> */}
          {/* <Route path="/home-three" element={<HomeThree />} /> */}
          {/* <Route path="/home-four" element={<HomeFour />} /> */}
          {/* <Route path="/home-five" element={<HomeFive />} /> */}
          {/* <Route path="/home-six" element={<HomeSix />} /> */}
          {/* <Route path="/home-seven" element={<HomeSeven />} /> */}
          {/* <Route path="/home-eight" element={<HomeEight />} /> */}
          {/* <Route path="/our-team" element={<OurTeam />} /> */}
          {/* <Route path="/team-details" element={<TeamDetails />} /> */}
          {/* <Route path="/thank-you" element={<ThankYou />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/login-two" element={<LoginTwo />} /> */}
          {/* <Route path="/signup-two" element={<SignupTwo />} /> */}
          {/* <Route path="/basic-signup" element={<SignupPage />} /> */}
          {/* <Route path="/reset-password" element={<PasswordReset />} /> */}
          {/* <Route path="/change-password" element={<ChangePassword />} /> */}
          {/* <Route path="/download" element={<Download />} /> */}
          {/* <Route path="/review" element={<Review />} /> */}
          {/* <Route path="/faq" element={<Faq />} /> */}
          {/* <Route path="/blog-grid" element={<BlogGrid />} /> */}
          {/* <Route path="/blog-sidebar" element={<BlogWithSidebar />} /> */}
          {/* <Route path="/blog-details" element={<BlogDetails />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </>
  );
};

export default AppRoutes;
