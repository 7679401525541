import { GET_VEHICLE_TYPE_SUCCESS, GET_VEHICLE_TYPE_LOADING, GET_VEHICLE_TYPE_ERROR, GET_VEHICLE_TYPE_RESET } from '../types';
import { HTTP, GET_VEHICLE_TYPES } from '../../utility/api';

export const fetchingRequest = () => ({
  type: GET_VEHICLE_TYPE_LOADING
});
export const fetchingError = (error) => ({
  type: GET_VEHICLE_TYPE_ERROR,
  payload: error
})
export const fetchingSuccess = (data) => ({
  type: GET_VEHICLE_TYPE_SUCCESS,
  payload: data
})
export const getVehicleTypesReset = () => ({
  type: GET_VEHICLE_TYPE_RESET
})

export function getVehicleTypes(data) {
  return async dispatch => {
    dispatch(fetchingRequest());
    HTTP.post(GET_VEHICLE_TYPES, { type: 1 })
      .then((response) => {
        if (response.data.success) {
          dispatch(fetchingSuccess(response.data));
        } else {
          dispatch(fetchingError(response))
        }
      })
      .catch((error) => {
        dispatch(fetchingError(error))
      });
  }
};